.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }

        }

        input[type='text'],
        input[type='email'] {
            font-family: 'Coolvetica';
            width: 100%;
            border: 0;
            background: #001b2f;
            height: 50px;
            font-size: 16px;
            color: #fff;
            padding: 0 20px;
            box-sizing: border-box;
        }

        textarea {
            font-family: 'Coolvetica';
            width: 100%;
            border: 0;
            background: #001b2f;
            height: 50px;
            font-size: 16px;
            color: #fff;
            padding: 20px;
            min-height: 150px;
            box-sizing: border-box;
        }

        .flat-button {
            color: #00a0af;
            background: 0 0;
            font: 11px;
            letter-spacing: 3px;
            text-decoration: none;
            padding: 8px 10px;
            border: 1px solid #00a0af;
            border-radius: 4px;
            float: right;

            &:hover {
                background: #00a0af;
                color: #333;
    
            }
        }
    }
}