.text-zone {
  height: auto;
  animation: fadeIn 1s forwards;
}

.about-page {
  h2 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .photo-zone {
    img {
      margin-bottom: 15px;
      max-width: 100%;
    }
  }
}
