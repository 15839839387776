.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.animated-letters {
  user-select: none;
}

.animated-letters-span {
  white-space: nowrap;
}

.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 60%;
    transform: translateY(-50%);
    width: 75%;
    max-height: 90%;

    .studev-mobile {
      display: none;
    }
  }

  h1 {
    color: #fff;
    font-size: 75px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    img {
      margin-left: 10px;
      margin-right: 2px;
      width: 65px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    font-weight: 100;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #00a0af;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #00d2e6;
    margin-top: 15px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #00d2e6;
      color: #fff;
    }
  }
}

.about-page,
.resume-page,
.contact-page,
.projects-page {
  .flat-button {
    color: #00a0af;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #00d2e6;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #00d2e6;
      color: #fff;
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 60%;
    vertical-align: middle;
    display: table-cell;
    height: auto;
    max-height: 85%;

    h1 {
      font-size: 75px;
      font-family: 'Coolvetica';
      color: #00d2e6;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 0px;
    }

    h2 {
      font-size: 24px;
      font-family: 'Coolvetica';
      color: #fff;
      font-weight: 400;
    }

    h3 {
      font-size: 16px;
      font-family: 'Coolvetica';
      color: #8d8d8d;
      font-weight: 400;
    }

    h4 {
      font-size: 14px;
      font-family: 'Coolvetica';
      color: #8d8d8d;
      font-weight: 100;
      margin: 0px;
    }

    p {
      font-style: 24px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;
      margin: 5px 0px;

      &:nth-of-type(1) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.3s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.4s;
      }
    }

    a {
      color: #00d2e6;
    }
  }

  .photo-zone {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 25px;
    margin-top: 15px;

    img {
      height: auto;
      width: 100%;
      max-width: 300px;
      margin-bottom: 25px;
      opacity: 0;
      animation: fadeIn 1s ease-in-out forwards;
    }
  }
}

.section-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 10px;

  h2 {
    margin: 0 10px 0 0;
    opacity: 0.5;
    cursor: pointer;
    white-space: nowrap;
    color: white;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.75;
    }

    &.active {
      opacity: 1;
    }
  }
}

@media (max-width: 800px) {
  .about-page,
  .resume-page,
  .contact-page,
  .projects-page {
    .text-zone {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .studev-desktop {
    display: none;
  }
}
