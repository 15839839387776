.side-bar {
  background: #181818;
  width: 60px;
  height: 70px;
  border-bottom-right-radius: 10px;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: height 1.5s ease;

  &.open {
    height: 100%;
    border-radius: 0;
  }

  .logo {
    display: block;
    padding: 8px 0;
    user-select: none;
    z-index: 2;
    background: #181818;
    border-bottom-right-radius: 10px;
    cursor: pointer;

    &:active {
      transform: translateY(2px);
      box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    }

    img {
      display: block;
      margin: 6px auto;
      width: 42px;
      height: auto;

      &.sub-logo {
        width: 60px;
      }
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -200px;
    width: 100%;
    z-index: -1;

    &.open {
      margin-top: -120px;
    }

    a {
      font-family: 'Coolvetica';
      font-size: 22px;
      text-align: center;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #00d2e6;
        font-family: 'Coolvetica';

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.resume-link {
      &:after {
        content: 'RESUME';
      }
    }

    a.projects-link {
      &:after {
        content: 'PROJECTS';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.active {
      svg {
        color: #00d2e6;
      }
    }
  }

  .contact-links {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    text-align: center;
    margin: 0;
    z-index: -1;

    a {
      padding: 7px 0;
      display: block;
      font-size: 15px;
      line-height: 16px;

      &:hover svg {
        color: #00d2e6;
      }
    }
  }
}

@media (max-width: 800px) {
  .side-bar {
    width: 60px;
    height: 80px;
    position: fixed;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 0;
    overflow: hidden;
    transition: width 1.5s ease;

    &.open {
      height: 80px;
      width: 100%;
      border-radius: 0;
    }

    .contact-links {
      display: none;
    }

    .nav-links {
      flex-direction: row;
      position: absolute;
      margin-top: -30px;
      justify-content: center;
      height: 100%;
      overflow: hidden;

      &.open {
        margin-top: -30px;
      }

      a {
        font-size: 24px;
        margin: 5px 15px 5px 15px;
        text-align: center;
      }

      a.about-link {
        &:after {
          position: absolute;
          bottom: 0;
          right: 8px;
          content: 'ABOUT';
        }
      }

      a.resume-link {
        &:after {
          position: absolute;
          bottom: 0;
          right: 10px;
          content: 'RESUME';
        }
      }

      a.projects-link {
        &:after {
          position: absolute;
          bottom: 0;
          right: 12px;
          content: 'PROJECTS';
        }
      }

      a.contact-link {
        &:after {
          position: absolute;
          bottom: 0;
          right: 10px;
          content: 'CONTACT';
        }
      }
    }
  }
}
