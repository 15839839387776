@import 'animate.css';
@import '~loaders.css/src/animations/ball-pulse-sync';

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/helveticaneue.woff2') format('woff2'),
    url('./assets/fonts/helveticaneue.woff') format('woff');
}

@font-face {
    font-family: 'La Belle Aurore';
    src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2'),
    url('./assets/fonts/LaBelleAurore.woff') format('woff');
}

@font-face {
    font-family: 'Coolvetica';
    src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input, textarea {
    font-family: 'Helvetica Neue';
}

.loader-active {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 75px;
    height: 75px;
    animation: fadeOut 0.75s 0.75s;
    animation-fill-mode: forwards;
}