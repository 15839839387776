.project {
  display: flex;
  flex-direction: column;
  animation: fadeIn 1s forwards;
}

.graphmath-select,
.jchess-select,
.weightclub-select,
.ankr-select {
  white-space: nowrap;
}

.project-link-icon {
  font-size: 24px;
}

.project-links {
  margin-top: 5px;
  margin-bottom: 10px;
  .flat-button {
    display: flex;
    font-size: 16px;
    padding: 12px;
    margin-right: 10px;
  }
}

.code-container {
  background-color: #2d2d2d;
  border-radius: 8px;
  padding: 16px;
  margin: 20px 0;
  overflow-x: auto;

  code {
    font-family: 'Fira Code', monospace;
    font-size: 14px;
    color: #f8f8f2;
    line-height: 1.5;
    white-space: pre-wrap;

    .keyword {
      color: #66d9ef;
    }
    .variable {
      color: #a6e22e;
    }
    .string {
      color: #e6db74;
    }
    .comment {
      color: #75715e;
      font-style: italic;
    }
    .number {
      color: #ae81ff;
    }
    .function-name {
      color: #fd971f;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .preview {
    width: 100%;
  }
}

.list-header {
  margin: 5px 0px;
}
.arbadillo-list {
  color: #fff;
  position: relative;
  left: -20px;
  top: -15px;

  li {
    font-weight: 100;
  }
}
